import axiosInstance from "../axios";
import { offerTypes } from "../constants/constatns";
import { URLS } from "../constants/urls";
import store, { setLoading } from "../redux";
import {
  AccountsWithTriad,
  FilteredApiData,
  FiltredApiDataInterface,
  setCart,
  setFilteredApiData,
  setIndexValue,
} from "../redux/reducer/order-slice";
import {
  AccountsInterface,
  CartOrder,
  DiscountRanges,
  FilteredCart,
  OfferApiData,
  OrderHistory,
  SkuWithQuantity,
} from "../types";
import { sendEvent } from "./sendEvent";

const getFilteredOrderHistory = (data: OfferApiData, statusToCheck: string) => {
  const updatedOrderHistory = data.orderHistory
    .filter(
      (ele) =>
        ele.internalOrderStatus &&
        ele.internalOrderStatus.toLowerCase() === statusToCheck
    )
    .sort((a, b) => {
      const dateA = new Date(a.orderedDateString);
      const dateB = new Date(b.orderedDateString);
      return dateB.getTime() - dateA.getTime();
    });

  return updatedOrderHistory;
};

export const getFilteredOffersData = (data: OfferApiData) => {
  try {
    let brandAdded: string[] = [];
    let brandList: FiltredApiDataInterface[] = [];
    let openOrders: OrderHistory[] = [];
    let closeOrders: OrderHistory[] = [];
    let offerData = [];
    let lastOrder: OrderHistory | null = null;
    let startDate = "";
    let endDate = "";
    let accounts: AccountsInterface[] = [];
    let periodOrders: OrderHistory[] = [];

    if (data) {
      if (data.orderHistory) {
        openOrders = getFilteredOrderHistory(data, "open");
        closeOrders = getFilteredOrderHistory(data, "closed");
        periodOrders = data.orderHistory.filter(
          (prevOrder) => prevOrder.currentPeriod
        );
        if (data.orderHistory.length > 0) {
          lastOrder = data.orderHistory[data.orderHistory.length - 1];
        }
      }
      if (data.accounts) {
        const accountWithTriad = data.accounts
          .map((account) => {
            return {
              ...account,
              triad: getTriad(account),
            };
          })
          .filter(
            (account) =>
              account.customerServiceAccountStatus === "VERIFIED" ||
              account.customerServiceAccountStatus === "PENDING_VERIFICATION"
          );
        accounts = accountWithTriad;
      }
      if (data.clubbedOffers && data.clubbedOffers.length > 0) {
        for (let offerData of data.clubbedOffers) {
          if (
            offerData.brandIdentifier &&
            !brandAdded.includes(offerData.brandIdentifier)
          ) {
            brandAdded.push(offerData.brandIdentifier);

            let onInvoiceDiscountRanges: DiscountRanges[] = [];

            if (offerData.onInvoiceDiscountRanges) {
              onInvoiceDiscountRanges = offerData.onInvoiceDiscountRanges;
            }
            let purchased = 0;
            let closedPurchased = 0;
            for (let periodOrder of periodOrders) {
              for (let lineItems of periodOrder.orderLineItems) {
                if (lineItems.brandIdentifier === offerData.brandIdentifier) {
                  purchased = purchased + lineItems.units;
                  if (
                    periodOrder.internalOrderStatus?.toLowerCase() === "closed"
                  ) {
                    closedPurchased = closedPurchased + lineItems.units;
                  }
                }
              }
            }

            brandList.push({
              brand: offerData.brand,
              brandIdentifier: offerData.brandIdentifier,
              onInvoiceDiscountRanges,
              purchased,
              selected: 0,
              offerLevel: offerData.offerLevel,
              skuData: [],
              maxVolume: offerData.maxVolume - closedPurchased,
              actualMaxVolume: offerData.maxVolume,
              closedPurchased,
              openPurchased: purchased - closedPurchased,
            });
          }
        }

        for (let brand of brandList) {
          const skuData = data.clubbedOffers
            .filter(
              (offerData) =>
                offerData.skuIdentifier &&
                offerData.brandIdentifier === brand.brandIdentifier
            )
            .map((offerData) => {
              let purchased = 0;
              let closedPurchased = 0;
              for (let periodOrder of periodOrders) {
                for (let lineItems of periodOrder.orderLineItems) {
                  if (
                    lineItems.brandIdentifier === offerData.brandIdentifier &&
                    lineItems.skuIdentifier === offerData.skuIdentifier
                  ) {
                    purchased = purchased + lineItems.units;
                    if (
                      periodOrder.internalOrderStatus?.toLowerCase() ===
                      "closed"
                    ) {
                      closedPurchased = closedPurchased + lineItems.units;
                    }
                  }
                }
              }
              return {
                skuPurchased: purchased,
                brand: offerData.brand,
                brandIdentifier: offerData.brandIdentifier,
                ean: offerData.ean,
                maxLevelReached: offerData.maxLevelReached,
                maxVolume: offerData.maxVolume - closedPurchased,
                offerLevel: offerData.offerLevel,
                onInvoiceDiscountRanges: offerData.onInvoiceDiscountRanges,
                period: offerData.period,
                sku: offerData.sku,
                skuIdentifier: offerData.skuIdentifier,
                skuPrice: offerData.skuPrice,
                actualMaxVolume: offerData.maxVolume,
                closedPurchased,
              };
            });
          offerData.push({
            ...brand,
            skuData: skuData,
          });
        }
        startDate = data.clubbedOffers[0].period.startDateString;
        endDate = data.clubbedOffers[0].period.endDateString;
      }
    }

    return {
      lastOrder,
      openOrders,
      closeOrders,
      offerData,
      startDate,
      endDate,
      accounts: accounts,
    };
  } catch (error) {
    return {
      openOrders: [],
      closeOrders: [],
      offerData: [],
      startDate: "",
      endDate: "",
      lastOrder: null,
      accounts: [],
    };
  }
};

export const getBrandData = async () => {
  try {
    store.dispatch(setLoading(true));
    const state = store.getState();
    const authData = state.authenticationSlice;
    const locData = state.locSlice;
    const { data } = await axiosInstance.get(
      URLS.updatedOfferApi(
        authData.user.user_id as unknown as number,
        locData.loc as unknown as string,
        authData.user.phone
      )
    );
    store.dispatch(setLoading(false));
    if (data) {
      const updatedFilteredData = getFilteredOffersData(data);
      store.dispatch(setFilteredApiData(updatedFilteredData));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    console.log(error);
  }
};

export const findLocaleLang = (data: any, language?: any) => {
  let findDefaultLang = data?.find((item: any) => item?.isDefault === "yes");
  if (findDefaultLang) {
    let defaultLang = findDefaultLang.language;
    if (localStorage.getItem("current-lang")) {
      defaultLang = localStorage.getItem("current-lang");
    } else {
      localStorage.setItem("current-lang", defaultLang);
    }
    if (language) {
      defaultLang = language;
      localStorage.setItem("current-lang", language);
    }
    const localeData = data?.find(
      (langData: any) => langData?.language === defaultLang
    );
    if (localeData.locale) {
      return {
        language: localeData.language,
        ...localeData.locale,
      };
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export const downloadExportFile = (response: any, type: any, fileName: any) => {
  const url = window.URL.createObjectURL(new Blob([response.data || response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", `_blank`);
  if (response.headers["content-disposition"].split("filename=")[1]) {
    link.setAttribute(
      "download",
      response.headers["content-disposition"]
        .split("filename=")[1]
        .split('"')[1]
    );
  } else {
    link.setAttribute("download", `${fileName || "Export"}.${type}`);
  }

  document.body.appendChild(link);
  link.click();
};

export const handleDeleteOrder = async (orderId: string) => {
  if (orderId) {
    try {
      store.dispatch(setLoading(true));
      const { status } = await axiosInstance.delete(URLS.deleteOrder(orderId));
      store.dispatch(setLoading(false));
      if (status === 200) {
        sendEvent("delete-order-success", {
          orderId: orderId,
        });
        await getBrandData();
      }
    } catch (e) {
      store.dispatch(setLoading(false));
      console.log(e);
    }
  }
};

export const getTriad = (order: {
  wholesalerCode: string;
  accountCode: string;
  legalIdCode: string;
}) => {
  return order.wholesalerCode + order.accountCode + order.legalIdCode;
};

export const getBlankDraftOpenCard = (
  brandData: FiltredApiDataInterface,
  account: AccountsWithTriad,
  date: string,
  indexValue: number
) => {
  const state = store.getState();
  const locData = state.locSlice;
  const authData = state.authenticationSlice;
  const cart: CartOrder[] = state.orderSlice.cart;
  const skuData = brandData.skuData.map((sku) => {
    return {
      skuIdentifier: sku.skuIdentifier,
      sku: sku.sku,
      requestedVolume: 0,
      onInvoiceDiscount: 0,
      price: sku.skuPrice,
      uniqueId: null,
    };
  });
  return {
    loc: locData.loc,
    orderType: "ORDER",
    brandIdentifier: brandData.brandIdentifier,
    brand: brandData.brand,
    skuData,
    phoneNumber: authData.user.phone,
    orderPlacedBy: authData.rep.phone || authData.user.phone,
    accountCode: account.accountCode,
    orderDate: date,
    externalOrderStatus: "DRAFT",
    draftId: cart.length > 0 ? cart[0].draftId : -1,
    legalIdCode: account.legalIdCode,
    wholesalerCode: account.wholesalerCode,
    wholesalerName: account.wholesalerName,
    indexValue: indexValue + 1,
    isDraft: true,
    orderId: null,
  };
};

export const changeOrderAccount = (
  orderData: CartOrder[],
  order: CartOrder,
  account: AccountsWithTriad,
  date: string
) => {
  const updatedOrderData = orderData.map((orderItem) => {
    if (
      orderItem.brandIdentifier === order.brandIdentifier &&
      orderItem.orderDate === order.orderDate &&
      getTriad(orderItem) === getTriad(order)
    ) {
      return {
        ...orderItem,
        accountCode: account.accountCode,
        legalIdCode: account.legalIdCode,
        wholesalerCode: account.wholesalerCode,
        wholesalerName: account.wholesalerName,
        orderDate: date,
        isDraft: true,
      };
    } else {
      return orderItem;
    }
  });
  return updatedOrderData;
};

export const calculateDiscount = (
  DiscountRanges: DiscountRanges[],
  totalQunaity: number
) => {
  let discountValue: number | string = 0;
  if (DiscountRanges && DiscountRanges.length > 0) {
    for (let i = 0; i < DiscountRanges.length; i++) {
      if (i + 1 === DiscountRanges.length) {
        if (totalQunaity >= DiscountRanges[i].label) {
          discountValue = DiscountRanges[i].discount;
          break;
        }
      } else {
        if (totalQunaity >= DiscountRanges[i].label) {
          discountValue = DiscountRanges[i + 1].discount;
        }
      }
    }
    return discountValue || 0;
  } else {
    return 0;
  }
};

export const createOrderDataWithDiscount = (
  orderData: CartOrder[],
  discountValue: string | number,
  skuWithTotalQuantity?: SkuWithQuantity[]
) => {
  const finalOrderData: CartOrder[] = [];
  for (let order of orderData) {
    const requestedVolumeSelected = order.skuData.find(
      (orderItem) => orderItem.requestedVolume > 0
    );
    if (requestedVolumeSelected) {
      const skuWithDiscount: {
        skuIdentifier: string;
        sku: string;
        requestedVolume: number;
        onInvoiceDiscount: number;
        price: number;
        uniqueId: number | null;
        lineId?: number | null;
      }[] = [];
      for (let skuItem of order.skuData) {
        let findQunaitySku:
          | {
              skuIdentifier: string;
              onInvoiceDiscountRanges: DiscountRanges[];
              totalQuantity: number;
            }
          | undefined = undefined;
        if (skuWithTotalQuantity) {
          findQunaitySku = skuWithTotalQuantity.find(
            (sku) => sku.skuIdentifier === skuItem.skuIdentifier
          );
        }
        skuWithDiscount.push({
          ...skuItem,
          onInvoiceDiscount:
            skuWithTotalQuantity && findQunaitySku
              ? (calculateDiscount(
                  findQunaitySku.onInvoiceDiscountRanges,
                  findQunaitySku.totalQuantity
                ) as number)
              : (discountValue as number),
        });
      }
      finalOrderData.push({
        ...order,
        skuData: skuWithDiscount,
      });
    }
  }
  return finalOrderData;
};

export const getTncData = async (tncType: string) => {
  try {
    const state = store.getState();
    const locData = state.locSlice;
    const {
      data,
    }: {
      data: {
        languageCode: string;
        loc: string;
        termsAndCondition: string;
        type: string;
      }[];
    } = await axiosInstance(URLS.getTNCData(locData.loc as unknown as string));
    if (data && data.length > 0) {
      const findTNC = data.find(
        (item) =>
          item.languageCode === (locData?.locale?.language as any) &&
          item.type === tncType
      );
      if (findTNC?.termsAndCondition) {
        return findTNC.termsAndCondition;
      } else {
        return "";
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const getDatesToDisable = (
  orderData: CartOrder[],
  account: AccountsWithTriad,
  brandData: FiltredApiDataInterface
) => {
  const state = store.getState();
  const orderHistoryData: OrderHistory[] = [
    ...state.orderSlice.filteredApiData.closeOrders,
  ];
  const dateToDisable = orderData
    .filter((order) => getTriad(order) === account.triad)
    .map((order) => {
      return order.orderDate;
    });
  let previodConfirmOrderDates: string[] = [];
  for (let prevOrder of orderHistoryData) {
    if (prevOrder.currentPeriod) {
      const currentBrandOrderExist = prevOrder.orderLineItems.find(
        (lineItem) => lineItem.brandIdentifier === brandData.brandIdentifier
      );
      if (currentBrandOrderExist) {
        previodConfirmOrderDates.push(prevOrder.orderedDateString);
      }
    }
  }
  return [...dateToDisable, ...previodConfirmOrderDates];
};

export const changeQuantity = (
  orderData: CartOrder[],
  sku: {
    skuIdentifier: string;
    sku: string;
    requestedVolume: number;
    onInvoiceDiscount: number;
    price: number;
  },
  inputValue: number,
  order: CartOrder,
  operation: string
) => {
  const updatedOrderData: CartOrder[] = [];
  for (let orderItem of orderData) {
    if (orderItem.orderDate === order.orderDate) {
      const updatedSkuData = orderItem.skuData.map((skuItem) => {
        if (
          skuItem.skuIdentifier === sku.skuIdentifier &&
          getTriad(orderItem) === getTriad(order)
        ) {
          return {
            ...skuItem,
            requestedVolume:
              operation === "minus"
                ? skuItem.requestedVolume - 1
                : operation === "plus"
                ? skuItem.requestedVolume + 1
                : inputValue,
          };
        } else {
          return skuItem;
        }
      });
      updatedOrderData.push({
        ...orderItem,
        isDraft: true,
        skuData: updatedSkuData,
      });
    } else {
      updatedOrderData.push({ ...orderItem });
    }
  }
  return updatedOrderData;
};

export const calculateProgress = (
  brand: FiltredApiDataInterface,
  showCombine: boolean
) => {
  let maxVolume = 0;
  const state = store.getState();
  const filtredData: FilteredApiData = state.orderSlice.filteredApiData;
  if (brand.offerLevel?.toLowerCase() === offerTypes.brandLevel) {
    maxVolume = brand.actualMaxVolume;
  } else {
    for (let sku of brand.skuData) {
      maxVolume = maxVolume + sku.actualMaxVolume;
    }
  }
  let selected = getTotalUnits(brand);
  if (showCombine) {
    return (selected / maxVolume) * 100;
  } else {
    const findPruchasedSku = filtredData.offerData.find(
      (offerItem) => offerItem.brandIdentifier === brand.brandIdentifier
    );
    return ((findPruchasedSku?.purchased || 0) / maxVolume) * 100;
  }
};

export const getTotalUnits = (brand: FiltredApiDataInterface) => {
  const state = store.getState();
  const cartData: CartOrder[] = state.orderSlice.cart;
  const filtredData: FilteredApiData = state.orderSlice.filteredApiData;
  let totalUnits = 0;
  for (let cartItem of cartData) {
    if (cartItem.brandIdentifier === brand.brandIdentifier) {
      for (let skuOrderItem of cartItem.skuData) {
        totalUnits = totalUnits + skuOrderItem.requestedVolume;
      }
    }
  }
  for (let existingOrder of [
    ...filtredData.openOrders,
    ...filtredData.closeOrders,
  ]) {
    if (existingOrder.currentPeriod) {
      for (let lineItem of existingOrder.orderLineItems) {
        if (lineItem.brandIdentifier === brand.brandIdentifier) {
          const isDraftExist = cartData.find(
            (cartItem) =>
              cartItem.brandIdentifier === brand.brandIdentifier &&
              cartItem.orderId &&
              cartItem.orderId === existingOrder.orderId
          );
          if (!isDraftExist) {
            totalUnits = totalUnits + lineItem.units;
          }
        }
      }
    }
  }
  return totalUnits;
};

export const saveCheckoutDraft = async (
  filterCartData: FilteredCart[],
  callback: () => void
) => {
  try {
    store.dispatch(setLoading(true));
    const payload = [];
    const state = store.getState();
    const locData = state.locSlice;
    for (let filterItem of filterCartData) {
      for (let skuItem of filterItem.skuData) {
        if(skuItem.requestedVolume>0 && filterItem.isDraft){
        payload.push({
          loc: locData.loc,
          orderType: filterItem.orderType,
          internalOrderNumber: "",
          internalOrderStatus: "OPEN",
          externalOrderNumber: "",
          externalOrderDate: "",
          brandIdentifier: skuItem.brandIdentifier,
          brand: skuItem.brand,
          skuIdentifier: skuItem.skuIdentifier,
          sku: skuItem.sku,
          requestedVolume: skuItem.requestedVolume,
          invoicedVolume: "",
          onInvoiceDiscount: skuItem.onInvoiceDiscount || 0,
          onInvoiceFreeGoods: "",
          onInvoicePaymentTerms: "",
          offInvoiceDiscount: "",
          offInvoiceFreeGoods: "",
          phoneNumber: filterItem.phoneNumber,
          orderPlacedBy: filterItem.orderPlacedBy,
          accountCode: filterItem.accountCode,
          draftDate: filterItem.orderDate,
          externalOrderStatus: filterItem.externalOrderStatus,
          noOfPharmaciesOrder: 1,
          draftId: filterItem.draftId,
          orderId: skuItem?.orderId || null,
          legalIdCode: filterItem.legalIdCode,
          uniqueId: skuItem.uniqueId,
          wholesalerCode: filterItem.wholesalerCode,
          wholesalerName: filterItem.wholesalerName,
          orderLineId: skuItem?.lineId || null,
        });
      }
      }
    }
    await axiosInstance.post(URLS.createDraft, {
      userId: state.authenticationSlice.user.user_id,
      draftDetails: payload,
    });
    store.dispatch(setLoading(false));
    callback();
  } catch (e) {
    store.dispatch(setLoading(false));
    console.log(e);
  }
};


export const getDraftData = async () => {
  try {
    const state = store.getState();
    const authData = state.authenticationSlice;
    store.dispatch(setLoading(true));
    const { data } = await axiosInstance.get(
      URLS.getDraftData(authData.user.user_id, state.locSlice.loc || "")
    );
    store.dispatch(setLoading(false));
    let indexValue =state.orderSlice.indexValue;
    if (data && data.length > 0) {
      const filteredCartData: CartOrder[] = [];
      for (let draftItem of data) {
        const isExist = filteredCartData.find(
          (item) =>
            draftItem.brandIdentifier + draftItem.draftDate ===
              item.brandIdentifier + item.orderDate &&
            getTriad(item) === getTriad(draftItem)
        );
        if (!isExist) {
          indexValue = indexValue + 1;
          const skuData = data
            .filter(
              (item: any) =>
                draftItem.brandIdentifier + draftItem.draftDate ===
                  item.brandIdentifier + item.draftDate &&
                getTriad(item) === getTriad(draftItem)
            )
            .map((item: any) => {
              return {
                skuIdentifier: item.skuIdentifier,
                sku: item.sku,
                requestedVolume: item.requestedVolume,
                onInvoiceDiscount: item.onInvoiceDiscount,
                price: item.skuPrice,
                uniqueId: item.uniqueId,
                lineId: item.orderLineId,
              };
            });

          filteredCartData.push({
            loc: draftItem.loc,
            orderType: draftItem.orderType,
            brandIdentifier: draftItem.brandIdentifier,
            brand: draftItem.brand,
            skuData,
            phoneNumber: authData.user.phone,
            accountCode: draftItem.accountCode,
            orderDate: draftItem.draftDate,
            externalOrderStatus: draftItem.externalOrderStatus || "DRAFT",
            orderPlacedBy: authData.rep.phone || authData.user.phone,
            draftId: draftItem.draftId,
            legalIdCode: draftItem.legalIdCode,
            wholesalerCode: draftItem.wholesalerCode,
            wholesalerName: draftItem.wholesalerName,
            indexValue,
            orderId: draftItem.orderId || null,
            isDraft: true,
          });
        }
      }
      if (filteredCartData.length > 0) {
        store.dispatch(setIndexValue(indexValue));
        store.dispatch(setCart(filteredCartData));
      }
    } else {
      store.dispatch(setCart([]));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    store.dispatch(setCart([]));
    console.log(error);
  }
};