/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseOrderCard from "../../../components/CloseOrderCard/CloseOrderCard";
import OpenOderCard from "../../../components/OpenOderCard/OpenOderCard";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  Cart,
  FilteredApiData,
  Loc,
  Locale,
  OrderData,
  RepData,
  SelectedBrand,
  setCurrentScreen,
  setLoading,
  setOrderData,
  User,
} from "../../../utils/redux";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { screenNames } from "../../../utils/constants/screenNames";
import OfferGadget from "../../../components/OfferGadget/OfferGadget";
import DialogBox from "../../../components/DialogBox/DialogBox";
import "./index.css";
import { themeColors } from "../../../utils/constants/themeColors";
import { offerTypes } from "../../../utils/constants/constatns";
import {
  CartOrder,
  OrderHistory,
  SkuWithQuantity,
  skuWithUniqueId,
} from "../../../utils/types";
import dayjs from "dayjs";
import {
  calculateDiscount,
  createOrderDataWithDiscount,
  getBlankDraftOpenCard,
  getBrandData,
  getDatesToDisable,
  getTriad,
} from "../../../utils/helperFunctions/helperFunction";
import {
  AccountsWithTriad,
  IndexValue,
  setIndexValue,
} from "../../../utils/redux/reducer/order-slice";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import { sendEvent } from "../../../utils/helperFunctions/sendEvent";
import { eventNames } from "../../../utils/constants/events";

const Order = () => {
  const locale = useSelector(Locale);
  const [showAccountList, setShowAccountList] = useState(false);
  const dispatch = useDispatch();
  const brandData = useSelector(SelectedBrand);
  const filtredData = useSelector(FilteredApiData);
  const [closeOrders, setCloseOrders] = useState<OrderHistory[]>([]);
  const orderData = useSelector(OrderData);
  const cartData = useSelector(Cart);
  const [hideAddWholesaler, setHideAddWholesaler] = useState(false);
  const [disableDates, setDisableDates] = useState<
    { triad: string; dateToExclude: string[] }[]
  >([]);
  const [orderDeleteList, setOrderDeleteList] = useState<number[]>([]);
  const [deletedConfirmDraft, setDeletedConfirmDraft] = useState<number[]>([]);
  const loc = useSelector(Loc);
  const user = useSelector(User);
  let indexValue = useSelector(IndexValue);
  const repData = useSelector(RepData);

  const createConfirmOpenOrderCard = (orderDataUnsavedData: CartOrder[]) => {
    const filteredPeriodOrders: CartOrder[] = [];
    for (let openOrder of filtredData.openOrders) {
      if (openOrder.currentPeriod) {
        const isDraftAlreadyCreated = orderDataUnsavedData.find(
          (orderItem) => orderItem.orderId === openOrder.orderId
        );
        if (!isDraftAlreadyCreated) {
          const isBrandOrderExist = openOrder.orderLineItems.find(
            (lineItem) => lineItem.brandIdentifier === brandData.brandIdentifier
          );
          if (isBrandOrderExist) {
            const skuData: skuWithUniqueId[] = [];
            for (let skuItem of brandData.skuData) {
              const findSkuOrder = openOrder.orderLineItems.find(
                (lineItem) =>
                  lineItem.brandIdentifier === brandData.brandIdentifier &&
                  lineItem.skuIdentifier === skuItem.skuIdentifier
              );
              skuData.push({
                skuIdentifier: skuItem.skuIdentifier,
                sku: skuItem.sku,
                requestedVolume: findSkuOrder?.units || 0,
                onInvoiceDiscount: findSkuOrder?.onInvoiceDiscount || 0,
                price: skuItem.skuPrice,
                uniqueId: null,
                lineId: findSkuOrder?.id || null,
              });
            }
            filteredPeriodOrders.push({
              loc: loc,
              orderType: "ORDER",
              brandIdentifier: brandData.brandIdentifier,
              brand: brandData.brand,
              skuData: skuData,
              phoneNumber: user.phone,
              accountCode: openOrder.accountCode,
              orderDate: openOrder.orderedDateString,
              externalOrderStatus: openOrder.status,
              orderPlacedBy: repData.phone || user.phone,
              orderId: openOrder.orderId,
              legalIdCode: openOrder.legalIdCode,
              wholesalerCode: openOrder.wholesalerCode,
              wholesalerName: openOrder.wholesalerName,
              indexValue: indexValue + 1,
              isDraft: false,
              openOrderId: openOrder.orderId,
              draftId: cartData.length > 0 ? cartData[0].draftId : -1,
            });
            indexValue = indexValue + 1;
          }
        }
      }
    }
    dispatch(setIndexValue(indexValue + 1));
    return filteredPeriodOrders;
  };

  const handleWholesalerSelection = (
    triad: string,
    account: AccountsWithTriad,
    nextDate?: string
  ) => {
    let dateToCheck = nextDate ? nextDate : dayjs().format("YYYY-MM-DD");
    const isExist = orderData.find(
      (order) => order.orderDate === dateToCheck && triad === getTriad(order)
    );
    let isExistInDisableDates = false;
    const updatedDisableDates = [];
    for (let account of filtredData.accounts) {
      const dateToDisable = getDatesToDisable(orderData, account, brandData);
      updatedDisableDates.push({
        triad: account.triad,
        dateToExclude: dateToDisable,
      });
    }
    for (let datesWithTriad of updatedDisableDates) {
      if (datesWithTriad.triad === triad) {
        const findDate = datesWithTriad.dateToExclude.find(
          (dateValue) => dateValue === dateToCheck
        );
        if (findDate) {
          isExistInDisableDates = true;
        }
        break;
      }
    }
    if (isExist || isExistInDisableDates) {
      if (dateToCheck === filtredData.endDate) {
        alert(locale?.general?.ordering?.snackbars?.noMoreDate);
      } else {
        let nextDate = dayjs(dateToCheck).add(1, "day").format("YYYY-MM-DD");
        handleWholesalerSelection(triad, account, nextDate);
      }
    } else {
      const blankOpenCard = getBlankDraftOpenCard(
        brandData,
        account,
        dateToCheck,
        indexValue
      );
      dispatch(setOrderData([blankOpenCard, ...orderData]));
      dispatch(setIndexValue(indexValue + 1));
    }
  };

  const handleContinue = async () => {
    try {
      const today = dayjs().startOf("day");
      const pastDatesDrafts = orderData.filter((elm) =>
        dayjs(elm.orderDate).startOf("day").isBefore(today)
      );
      const pastDraftWithQuntity: CartOrder[] = [];
      for (let pastDraft of pastDatesDrafts) {
        const haveRequestedVolume = pastDraft.skuData.find(
          (item) => item.requestedVolume > 0
        );
        if (haveRequestedVolume) {
          pastDraftWithQuntity.push(pastDraft);
        }
      }
      if (pastDraftWithQuntity.length > 0) {
        sendEvent(eventNames.pastDateDrafts, pastDraftWithQuntity);
      } else {
        let totalQunaity = 0;
        for (let order of orderData) {
          for (let skuItem of order.skuData) {
            totalQunaity = skuItem.requestedVolume + totalQunaity;
          }
        }
        const exitingCartWithoutCurrentBrand = cartData.filter(
          (cartItem) => cartItem.brandIdentifier !== brandData.brandIdentifier
        );

        let finalData: CartOrder[] = [];
        if (brandData.offerLevel?.toLowerCase() === offerTypes.brandLevel) {
          let discountValue = calculateDiscount(
            brandData.onInvoiceDiscountRanges,
            totalQunaity
          );
          finalData = createOrderDataWithDiscount(orderData, discountValue);
        } else {
          const skuWithQuantity: SkuWithQuantity[] = [];
          for (let skuItem of brandData.skuData) {
            let totalQuantity = 0;
            for (let orderItem of orderData) {
              for (let orderSku of orderItem.skuData) {
                if (orderSku.skuIdentifier === skuItem.skuIdentifier) {
                  totalQuantity = totalQuantity + orderSku.requestedVolume;
                }
              }
            }
            skuWithQuantity.push({
              skuIdentifier: skuItem.skuIdentifier as string,
              onInvoiceDiscountRanges: skuItem.onInvoiceDiscountRanges,
              totalQuantity,
            });
          }
          finalData = createOrderDataWithDiscount(
            orderData,
            0,
            skuWithQuantity
          );
        }

        const finalDraft = [];
        for (let cartItem of [
          ...exitingCartWithoutCurrentBrand,
          ...finalData,
        ]) {
          for (let cartSkuItem of cartItem.skuData) {
            if (cartSkuItem.requestedVolume > 0 && cartItem.isDraft) {
              finalDraft.push({
                loc: loc,
                orderType: cartItem.orderType,
                internalOrderNumber: "",
                internalOrderStatus: "OPEN",
                externalOrderNumber: "",
                externalOrderDate: "",
                brandIdentifier: cartItem.brandIdentifier,
                brand: cartItem.brand,
                skuIdentifier: cartSkuItem.skuIdentifier,
                sku: cartSkuItem.sku,
                requestedVolume: cartSkuItem.requestedVolume,
                invoicedVolume: "",
                onInvoiceDiscount: cartSkuItem.onInvoiceDiscount || 0,
                onInvoiceFreeGoods: "",
                onInvoicePaymentTerms: "",
                offInvoiceDiscount: "",
                offInvoiceFreeGoods: "",
                phoneNumber: cartItem.phoneNumber,
                orderPlacedBy: cartItem.orderPlacedBy,
                accountCode: cartItem.accountCode,
                draftDate: cartItem.orderDate,
                externalOrderStatus: cartItem.externalOrderStatus,
                noOfPharmaciesOrder: 1,
                draftId: cartItem.draftId,
                orderId: cartItem.orderId,
                legalIdCode: cartItem.legalIdCode,
                uniqueId: cartSkuItem.uniqueId,
                wholesalerCode: cartItem.wholesalerCode,
                wholesalerName: cartItem.wholesalerName,
                orderLineId: cartSkuItem?.lineId || null,
              });
            }
          }
        }

        const sameDateDraft: {
          orderDate: string;
          accountCode: string;
          wholesalerCode: string;
          wholesalerName: string;
          legalIdCode: string;
        }[] = [];
        for (let deletedDraft of deletedConfirmDraft) {
          const filteredOpenOrder = filtredData.openOrders.find(
            (openOrder) => openOrder.orderId === deletedDraft
          );

          if (filteredOpenOrder) {
            const isSameDateDraftExist = finalData.find(
              (finalItem) =>
                getTriad(finalItem) === getTriad(filteredOpenOrder) &&
                filteredOpenOrder.orderedDateString === finalItem.orderDate
            );
            if (isSameDateDraftExist) {
              sameDateDraft.push({
                orderDate: isSameDateDraftExist.orderDate,
                accountCode: isSameDateDraftExist.legalIdCode,
                wholesalerCode: isSameDateDraftExist.wholesalerCode,
                wholesalerName: isSameDateDraftExist.wholesalerName,
                legalIdCode: isSameDateDraftExist.legalIdCode,
              });
            }
          }
        }
        dispatch(setLoading(true));
        if (sameDateDraft.length > 0) {
          dispatch(setLoading(false));
          sendEvent(eventNames.duplicateOrderDateInDraft, sameDateDraft);
        } else {
          if (orderDeleteList.length > 0) {
            await axiosInstance.delete(URLS.deleteOrderLineItems, {
              data: orderDeleteList,
            });
            await getBrandData();
          }
          setOrderDeleteList([]);
          await axiosInstance.post(URLS.createDraft, {
            userId: user.user_id,
            draftDetails: finalDraft,
          });
          dispatch(setLoading(false));
          sendEvent(eventNames.continueDraftOrder, finalDraft);
          dispatch(setOrderData([]));
          dispatch(setCurrentScreen(screenNames.brandSelectionScreen));
        }
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log(e);
    }
  };

  useEffect(() => {
    if (filtredData.closeOrders) {
      const filtredCloseOrders: OrderHistory[] = [];
      for (let closeOrder of filtredData.closeOrders) {
        if (closeOrder.currentPeriod) {
          const orderWithCurrentBrand = closeOrder.orderLineItems.filter(
            (closeOrder) =>
              closeOrder.brandIdentifier === brandData.brandIdentifier
          );
          if (orderWithCurrentBrand.length > 0) {
            filtredCloseOrders.push({
              ...closeOrder,
              orderLineItems: orderWithCurrentBrand,
            });
          }
        }
      }
      setCloseOrders(filtredCloseOrders);
    }
  }, []);

  useEffect(() => {
    if (filtredData.accounts.length > 0) {
      if (cartData) {
        const isAvailableInCart = cartData.find(
          (cartItem) => cartItem.brandIdentifier === brandData.brandIdentifier
        );
        if (isAvailableInCart) {
          const existingCartData = cartData.filter(
            (cartItem) => cartItem.brandIdentifier === brandData.brandIdentifier
          );

          const existingCardWithAllSku: CartOrder[] = [];
          for (let existingItem of existingCartData) {
            if (existingItem.brandIdentifier === brandData.brandIdentifier) {
              if (existingItem.skuData.length === brandData.skuData.length) {
                existingCardWithAllSku.push(existingItem);
              } else {
                const updatedCardSku: skuWithUniqueId[] = [];
                for (let skuElement of brandData.skuData) {
                  const findSku = existingItem.skuData.find(
                    (ele) => ele.skuIdentifier === skuElement.skuIdentifier
                  );
                  updatedCardSku.push({
                    skuIdentifier: skuElement.skuIdentifier as string,
                    sku: skuElement.sku as string,
                    onInvoiceDiscount: findSku?.onInvoiceDiscount || 0,
                    requestedVolume: findSku?.requestedVolume || 0,
                    price: findSku?.price || skuElement.skuPrice,
                    uniqueId: findSku?.uniqueId || null,
                    lineId: findSku?.lineId || null,
                  });
                }
                existingCardWithAllSku.push({
                  ...existingItem,
                  skuData: updatedCardSku,
                });
              }
            }
          }
          const confirmOpenOrderCard = createConfirmOpenOrderCard([
            ...existingCardWithAllSku,
          ]);
          dispatch(
            setOrderData([...existingCardWithAllSku, ...confirmOpenOrderCard])
          );
        } else {
          const confirmOpenOrderCard = createConfirmOpenOrderCard(orderData);
          if (confirmOpenOrderCard.length === 0) {
            handleWholesalerSelection(
              filtredData.accounts[0].triad,
              filtredData.accounts[0]
            );
          } else {
            dispatch(setOrderData(confirmOpenOrderCard));
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (orderData.length > 0) {
      const updatedDisableDates = [];
      for (let account of filtredData.accounts) {
        const dateToDisable = getDatesToDisable(orderData, account, brandData);
        updatedDisableDates.push({
          triad: account.triad,
          dateToExclude: dateToDisable,
        });
      }
      setDisableDates(updatedDisableDates);
    }
  }, [orderData]);

  useEffect(() => {
    if (orderData) {
      if (brandData.offerLevel?.toLowerCase() === offerTypes.brandLevel) {
        let totalQuantity = 0;
        if (brandData.offerLevel?.toLowerCase() === offerTypes.brandLevel) {
          for (let order of orderData) {
            for (let skuData of order.skuData) {
              totalQuantity = totalQuantity + skuData.requestedVolume;
            }
          }
          if (totalQuantity >= brandData.maxVolume) {
            setHideAddWholesaler(true);
          } else {
            setHideAddWholesaler(false);
          }
        }
      } else {
        let maxReached: boolean[] = [];
        for (let skuDataItem of brandData.skuData) {
          let totalUnits = 0;
          for (let orderItem of orderData) {
            const findSku = orderItem.skuData.find(
              (orderSkuItem) =>
                orderSkuItem.skuIdentifier === skuDataItem.skuIdentifier
            );
            if (findSku) {
              totalUnits = totalUnits + findSku.requestedVolume;
            }
          }
          if (totalUnits >= skuDataItem.maxVolume) {
            maxReached.push(true);
          } else {
            maxReached.push(false);
          }
        }
        const isAllMaxReached = maxReached.find((item) => item === false);
        if (isAllMaxReached === false) {
          setHideAddWholesaler(false);
        } else {
          setHideAddWholesaler(true);
        }
      }
    }
  }, [orderData]);

  return (
    <Box className="pt20 flex fdc fullHeight borderBox">
      <Box className="pl20 pr20">
        <Typography color={"primary"} fontSize={16}>
          {brandData.brand}
        </Typography>
        <Box className="flex jc-sb mb5 mt5">
          <Typography fontSize={12}>
            <span style={{ color: themeColors.primaryType1 }}>
              {locale?.general?.common?.labels?.startDate}:{" "}
            </span>
            {filtredData.startDate}
          </Typography>
          <Typography fontSize={12}>
            <span style={{ color: themeColors.primaryType1 }}>
              {locale?.general?.common?.labels?.endDate}:{" "}
            </span>
            {filtredData.endDate}
          </Typography>
        </Box>
        <Box className="br5" border={"1px solid #959595"} padding={"18px 12px"}>
          {brandData.offerLevel?.toLowerCase() === offerTypes.brandLevel && (
            <OfferGadget
              isSKUOffer={false}
              discountRanges={brandData.onInvoiceDiscountRanges}
            />
          )}
          {brandData.offerLevel?.toLowerCase() === offerTypes.skuLevel &&
            brandData.skuData.map((sku) => {
              return (
                <OfferGadget
                  key={sku.skuIdentifier}
                  discountRanges={sku.onInvoiceDiscountRanges}
                  isSKUOffer={true}
                  skuData={sku}
                />
              );
            })}
        </Box>
        <Box textAlign={"center"} mt={1.5} height={"28px"}>
          {!hideAddWholesaler && (
            <CustomButton
              background="white"
              hoverBackground="primary.main"
              color="primary"
              align="center"
              onClick={() => {
                setShowAccountList(true);
              }}
              padding="3px 20px"
            >
              <Typography fontSize={14}>
                {locale?.general?.order?.buttons?.selectWholesaler}
              </Typography>
            </CustomButton>
          )}
        </Box>
      </Box>
      <Box flex={1} className="auto-o pl20 pr20" mt={1.5}>
        {orderData &&
          orderData.map((order, index) => {
            return (
              <OpenOderCard
                deletedConfirmDraft={deletedConfirmDraft}
                setDeletedConfirmDraft={setDeletedConfirmDraft}
                orderDeleteList={orderDeleteList}
                setOrderDeleteList={setOrderDeleteList}
                isConfirmOrder={order?.openOrderId ? true : false}
                hideAddWholesaler={hideAddWholesaler}
                disableDates={disableDates}
                key={order.brandIdentifier + getTriad(order) + order.orderDate}
                order={order}
              />
            );
          })}
        {closeOrders.length > 0 && (
          <Box borderTop={"1px solid #c5c1c1"} mb={1} pt={1} mt={"25px"}>
            <Typography fontSize={14} fontWeight={500}>
              {locale?.general?.common?.labels?.closedOrders}
            </Typography>
          </Box>
        )}
        {closeOrders.map((closeOrder) => {
          return (
            <CloseOrderCard
              iconColor="white"
              background="#3f3f3f"
              color="#959595"
              key={closeOrder.orderId}
              closeOrder={closeOrder}
            />
          );
        })}
      </Box>
      <Box className="flex jc-c g20 pt5 pb5">
        <CustomButton
          onClick={() => {
            dispatch(setOrderData([]));
            dispatch(setCurrentScreen(screenNames.brandSelectionScreen));
            sendEvent(eventNames.backToBrandSelection, {});
          }}
          align="space-evenly"
          width={"155px"}
          background="#e6e6e6"
          color="#959595"
          hoverBackground="#d2cece"
        >
          <ArrowCircleLeftOutlinedIcon />
          <Typography fontSize={16}>
            {locale?.general?.common?.buttons?.back}
          </Typography>
        </CustomButton>
        <CustomButton
          onClick={handleContinue}
          align={"space-evenly"}
          width={"155px"}
        >
          <Typography fontSize={16}>
            {locale?.general?.common?.buttons?.continue}
          </Typography>
          <ArrowCircleRightOutlinedIcon />
        </CustomButton>
      </Box>
      <DialogBox open={showAccountList} setOpen={setShowAccountList}>
        <Box className="p10 accountL-list-container">
          {filtredData.accounts.map((account) => {
            return (
              <Box
                onClick={() => {
                  setShowAccountList(false);
                  handleWholesalerSelection(account.triad, account);
                }}
                key={account.triad}
                className="account-list-item"
              >
                <Typography fontSize={14}>
                  {account.wholesalerName} - {account.accountCode}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </DialogBox>
    </Box>
  );
};

export default Order;
