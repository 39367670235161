/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ProgressCard from "../ProgressCard/ProgressCard";
import { DiscountRanges } from "../../utils/types";
import { useSelector } from "react-redux";
import { Locale, SelectedBrand } from "../../utils/redux";
import { FilteredApiData, OrderData, skuDataInterface } from "../../utils/redux/reducer/order-slice";

interface OfferGadgetProps {
  discountRanges: DiscountRanges[];
  isSKUOffer: boolean;
  skuData?: skuDataInterface;
}

const OfferGadget: FC<OfferGadgetProps> = ({ discountRanges, isSKUOffer, skuData }) => {
  const brandData = useSelector(SelectedBrand);
  const orderData = useSelector(OrderData);
  const [selected,setSelected] = useState(0);
  const locale = useSelector(Locale);
  const filteredData = useSelector(FilteredApiData);

  useEffect(()=>{
    if(orderData.length>0){
      let totalUnits = 0;
      for(let closeOrder of filteredData.closeOrders){
        if(closeOrder.currentPeriod){
          for(let lineItem of closeOrder.orderLineItems){
            if(skuData){
              if(lineItem.skuIdentifier===skuData.skuIdentifier){
                totalUnits = totalUnits + lineItem.units;
              }
            }else{
              if(lineItem.brandIdentifier===brandData.brandIdentifier){
                totalUnits = totalUnits + lineItem.units
              }
            }
          }
        }
      }
      for(let orderItem of orderData){
        if(skuData){
        const skuOrderData = orderItem.skuData.filter(skuItem => skuItem.skuIdentifier===skuData?.skuIdentifier);
        for(let skuOrderItem of skuOrderData){
          totalUnits = totalUnits + skuOrderItem.requestedVolume;
        }
      }
      else if(orderItem.brandIdentifier===brandData.brandIdentifier){
          for(let skuOrderItem of orderItem.skuData){
            totalUnits = skuOrderItem.requestedVolume + totalUnits;
          }
        }
    }
      setSelected(totalUnits);
    }else{
      setSelected(0);
    }
  },[orderData])

  return (
    <Box>
      <Grid container>
        {isSKUOffer && <Grid item width={'110px'}/>}
        {discountRanges
          ?.slice(0, discountRanges.length - 1)
          ?.map((discount, index: number) => {
            return (
              <Grid xs item key={discount.label} className="flex jc-fe ai-fe">
                <Typography>{discountRanges[index + 1].discount}</Typography>
              </Grid>
            );
          })}
        <Grid item xs className="tar">
          <Typography>{locale?.general?.common?.labels?.onInvoice}</Typography>
        </Grid>
      </Grid>
      <ProgressCard
        skuData={skuData}
        isSKUOffer={isSKUOffer}
        purchased={!isSKUOffer ? brandData.purchased : skuData?.skuPurchased || 0 }
        selected={selected}
        discountRange={discountRanges}
        brand={brandData}
      />
      <Grid container>
      {isSKUOffer && <Grid item width={'110px'}/>}
        {discountRanges
          ?.slice(0, discountRanges.length - 1)
          ?.map((discount, index: number) => {
            return (
              <Grid
                xs
                item
                key={discount.label}
                className={`flex ${index === 0 ? "jc-sb" : "jc-fe"}`}
              >
                {index === 0 && <Typography>{locale?.general?.common?.labels?.value}</Typography>}
                <Typography>{discount.label}</Typography>
              </Grid>
            );
          })}
        <Grid item xs className="tar">
          <Typography>{brandData.actualMaxVolume}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OfferGadget;
