import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Locale } from "../../../utils/redux";
import { useSelector } from "react-redux";
import { tncPages } from "../../../utils/constants/constatns";
import { getTncData } from "../../../utils/helperFunctions/helperFunction";

const TermsCondition = () => {
  const locale = useSelector(Locale);
  const [tnc, setTnc] = useState("");

  useEffect(() => {
    if (locale.language) {
      getTncData(tncPages.termsAndCondition).then((res) => {
        if (res) {
          setTnc(res);
        }
      });
    }
  }, [locale.language]);

  return (
    <Box className="p20">
      <Typography fontSize={13} dangerouslySetInnerHTML={{ __html: tnc }} />
    </Box>
  );
};

export default TermsCondition;
