import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FilteredApiData,
  Locale,
  setCurrentScreen,
} from "../../../utils/redux";
import LinearProgress from "@mui/material/LinearProgress";
import "./index.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { screenNames } from "../../../utils/constants/screenNames";
import OrderCardHistory from "../OrderHistory/OrderHistoryCard";
import {
  calculateProgress,
  getBrandData,
} from "../../../utils/helperFunctions/helperFunction";
import { images } from "../../../utils/constants/images";

const UserWelcomeScreen = () => {
  const locale = useSelector(Locale);
  const filtredData = useSelector(FilteredApiData);
  const dispatch = useDispatch();
  const boxRef = useRef<any>(null);
  const [loaded, setLoaded] = useState(false);
  const [remaningSpace, setRemaningSpace] = useState(0);

  useEffect(() => {
    (async () => {
      await getBrandData();
    })();
  }, []);

  useLayoutEffect(() => {
    const updateHeight = () => {
      if (boxRef.current) {
        const spaceAvaialbe = boxRef.current.getBoundingClientRect().height;
        setRemaningSpace(spaceAvaialbe);
      }
    };
    updateHeight();
    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });
    if (boxRef.current) {
      resizeObserver.observe(boxRef.current);
    }
    return () => {
      if (boxRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(boxRef.current);
      }
    };
  }, []);

  return (
    <Box className="p20 flex fdc fullHeight borderBox">
      {filtredData.lastOrder && (
        <Box className="flex jc-sb">
          <Typography color="primary" fontSize={17}>
            {locale?.general?.homePage?.labels?.lastOrder}
          </Typography>
          <CustomButton
            onClick={() => {
              dispatch(setCurrentScreen(screenNames.orderHistory));
            }}
            fontSize={13}
            padding="0px 10px"
            width="fit-content"
          >
            {locale?.general?.common?.labels?.orderHistory}
          </CustomButton>
          {/* <CustomButton
            onClick={() => {
              dispatch(setCurrentScreen(screenNames.brandSelectionScreen));
            }}
            fontSize={13}
            padding="0px 10px"
            width="fit-content"
          >
            Brand Selection
          </CustomButton> */}
        </Box>
      )}
      {filtredData.lastOrder && (
        <Box className="mt15">
          <OrderCardHistory
            type={"open"}
            formType={"type1"}
            showIcons={false}
            orderData={filtredData.lastOrder}
          />
        </Box>
      )}
      {!filtredData.lastOrder && (
        <Typography fontSize={15} textAlign={"center"} color="secondary.main">
          {locale?.general?.orderHistory?.labels?.emptyOrder}
        </Typography>
      )}

      <Box className="flex jc-sb mt15">
        <Typography color="primary" fontSize={17}>
          {locale?.general?.homePage?.labels?.planProgress}
        </Typography>
        {filtredData.offerData && filtredData.offerData.length > 0 && (
          <Box className="flex ai-c g5">
            <Typography fontSize={11} fontWeight={700} color="primary">
              {locale?.general?.common?.labels?.endDate}:
            </Typography>
            <Typography fontSize={11}>{filtredData.endDate}</Typography>
          </Box>
        )}
      </Box>
      <Box>
        <Grid container>
          <Grid item>
            {filtredData.offerData.map((offer) => {
              return (
                <Typography
                  key={offer.brandIdentifier}
                  fontSize={13.2}
                  noWrap
                  mt={1.5}
                >
                  {offer.brand}
                </Typography>
              );
            })}
          </Grid>
          <Grid xs item>
            {filtredData.offerData.map((offer) => {
              if (!loaded) {
                setLoaded(true);
              }
              return (
                <LinearProgress
                  key={offer.brandIdentifier}
                  variant="determinate"
                  value={calculateProgress(offer, false)}
                  sx={{
                    marginTop: 1.5,
                    marginLeft: 1,
                    height: "20px",
                    backgroundColor: "secondary.main",
                    borderRadius: "5px",
                    "& .MuiLinearProgress-bar": {
                      transition: "none",
                      backgroundColor: "primary",
                      borderRadius: "5px",
                    },
                  }}
                />
              );
            })}
          </Grid>
        </Grid>
      </Box>
      <Box ref={boxRef} flex={1} />
      {loaded && remaningSpace > 150 && (
        <Box className="relative">
          <img
            alt="banner-template"
            className="bottom-banner-image"
            src={images.bannerTemplate}
            style={{
              height: `${Math.round(remaningSpace) - 22}px`,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default UserWelcomeScreen;
