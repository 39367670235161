import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Locale } from "../../utils/redux";
import { getTncData } from "../../utils/helperFunctions/helperFunction";
import { tncPages } from "../../utils/constants/constatns";

const Footer = () => {
  const locale = useSelector(Locale);
  const [tnc, setTnc] = useState("");

  useEffect(() => {
      if (locale.language) {
        getTncData(tncPages.footer).then((res) => {
          if (res) {
            setTnc(res);
          }
        });
      }
    }, [locale.language]);

  return (
    <Box p={"0px 7px"} color={"secondary.main"} >
      <Typography
        className="tnc-typography"
        dangerouslySetInnerHTML={{ __html: tnc }}
      ></Typography>
    </Box>
  );
};

export default Footer;
