export const offerTypes = {
    brandLevel: 'brand',
    skuLevel: 'sku'
}

export const tncPages = {
    homePage: "homepage",
    termsAndCondition: "termAndCondition",
    footer: "footer"
}

export const trustedOrigin = "https://cloud.yellow.ai";