import { Box, Typography } from "@mui/material";
import { themeColors } from "../../../utils/constants/themeColors";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { BannerLoaded, Locale, setBannerLoaded } from "../../../utils/redux";
import { FC, useEffect, useState } from "react";
import { welcomeAssests } from "./welcomeAssests";
import { getTncData } from "../../../utils/helperFunctions/helperFunction";
import { tncPages } from "../../../utils/constants/constatns";

interface WelcomeScreenProps {}

const WelcomeScreen: FC<WelcomeScreenProps> = () => {
  const locale = useSelector(Locale);
  const [banner, setBanner] = useState<{
    language: string;
    bg: any;
    bannerAssets: any;
  } | null>(null);
  const dispatch = useDispatch();
  const bannerLoaded = useSelector(BannerLoaded);
  const [tnc, setTnc] = useState("");

  useEffect(() => {
    if (locale.language) {
      const bannerData = welcomeAssests.find(
        (data) => data.language === locale.language
      );
      if (bannerData) {
        setBanner(bannerData);
      }
      getTncData(tncPages.homePage).then((res) => {
        if (res) {
          setTnc(res);
        }
      });
    }
  }, [locale.language]);

  useEffect(() => {
    if (banner) {
      const bgImg = new Image();
      bgImg.src = banner.bg;
      bgImg.onload = () => {
        const assetsImg = new Image();
        assetsImg.src = banner.bannerAssets;
        assetsImg.onload = () => {
          dispatch(setBannerLoaded(true));
        };
        assetsImg.onerror = () => {
          console.error("Failed to load assets image");
        };
      };
      bgImg.onerror = () => {
        console.error("Failed to load background image");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banner]);

  return (
    <Box className="fullHeight flex fdc">
      <Box
        className="welcome-banner"
        sx={{
          backgroundImage:
            bannerLoaded && banner ? `url(${banner.bg})` : "none",
        }}
      >
        {banner && (
          <img
            alt="gsk-welcome-banner"
            src={banner.bannerAssets}
            width={"100%"}
            height={"100%"}
            style={{ objectFit: "contain" }}
          />
        )}
      </Box>
      <Box className="pt5 pb5 pl10 pr10">
        <Typography
          className="tnc-typography"
          color={themeColors.secondaryType2}
          dangerouslySetInnerHTML={{ __html: tnc }}
        ></Typography>
      </Box>
    </Box>
  );
};

export default WelcomeScreen;
