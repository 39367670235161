/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Grid, IconButton, Typography } from "@mui/material";
import { themeColors } from "../../utils/constants/themeColors";
import { FC, useEffect, useState } from "react";
import "./styles.css";
import { FiltredApiDataInterface, skuDataInterface } from "../../utils/redux/reducer/order-slice";

const ProgressCardCommonStyle = (
  index: number,
  progressBackground: string,
  mainBackground: string,
  dataLength: number,
  cardValues: any,
  isSKUOffer: boolean
) => {
  return {
    height: !isSKUOffer ? "30px" : "20px",
    background: mainBackground,
    borderTopRightRadius: index === dataLength - 1 ? "5px" : "0px",
    borderBottomRightRadius: index === dataLength - 1 ? "5px" : "0px",
    borderTopLeftRadius: index === 0 ? "5px" : "0px",
    borderBottomLeftRadius: index === 0 ? "5px" : "0px",
    "& .MuiLinearProgress-bar": {
      transition: "none",
      backgroundColor: progressBackground,
      borderRadius:
        cardValues.fillValue && cardValues.fillValue < 100 ? "5px" : 0,
    },
  };
};

type DiscountRangeType = {
  discount: string;
  label: number;
  fillValue?: number;
}[];

interface ProgressCardProps {
  selected: number;
  purchased: number;
  brand: FiltredApiDataInterface;
  discountRange: DiscountRangeType;
  onBrandSelection?: () => void;
  isSKUOffer?: boolean;
  skuData?: skuDataInterface
}

const ProgressCard: FC<ProgressCardProps> = ({
  brand,
  discountRange,
  purchased,
  selected,
  onBrandSelection,
  isSKUOffer = false,
  skuData
}) => {
  const [discountData, setDiscountData] =
    useState<DiscountRangeType>(discountRange);
  const [purchaseData, setPurchaseData] =
    useState<DiscountRangeType>(discountRange);
  const selectedQuntity = selected;
  const previousOrderQuntity = purchased;

  const calculatePercentage = (part: number, total: number) => {
    if (total === 0) {
      return 0;
    }
    return (part / total) * 100;
  };

  const updateFillValue = (
    data: { discount: string; label: number },
    index: number,
    quantity: number
  ) => {
    if (index === 0) {
      if (quantity === 0) {
        return 0;
      } else {
        let getPercent = calculatePercentage(quantity, data.label);
        return getPercent;
      }
    } else {
      let previousRange = discountData[index - 1];
      if (quantity <= previousRange.label) {
        return 0;
      } else {
        let newTotal = data.label - previousRange.label;
        let actualQunt = quantity - previousRange.label;
        let getPercent = calculatePercentage(actualQunt, newTotal);
        return getPercent;
      }
    }
  };

  const updateFillData = (
    data: DiscountRangeType,
    orderQuntity: number,
    isPreviousOrderData: boolean
  ) => {
    const updatedData = data.map((dataItem: any, index: number) => {
      if (orderQuntity >= dataItem.label) {
        return { ...dataItem, fillValue: 100 };
      } else {
        let value = updateFillValue(dataItem, index, orderQuntity);
        return { ...dataItem, fillValue: value };
      }
    });
    if (isPreviousOrderData) {
      setPurchaseData(updatedData);
    } else {
      setDiscountData(updatedData);
    }
  };

  useEffect(() => {
    updateFillData(discountData, selectedQuntity, false);
  }, [selectedQuntity]);

  useEffect(() => {
    updateFillData(purchaseData, previousOrderQuntity, true);
  }, [previousOrderQuntity]);

  return (
    <Box>
      {onBrandSelection && (
        <Grid container>
          <Grid xs item>
            <Typography
              fontSize={14}
              style={{ fontWeight: 500 }}
              marginBottom={0.5}
            >
              {brand.brand}
            </Typography>
          </Grid>
          <Grid xs={1} item textAlign={"center"}>
            <Typography>{selectedQuntity}</Typography>
          </Grid>
        </Grid>
      )}

      <Grid container>
        {skuData && (
          <Grid item width={"110px"}>
            <Typography fontSize={14} color={"primary"}>
              {skuData.sku}
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <Box className="relative">
            <Grid container>
              {discountData.map((discount, index: number) => {
                return (
                  <Grid xs item key={discount.label} className="relative">
                    <LinearProgress
                      variant="determinate"
                      value={discount.fillValue ? discount.fillValue : 0}
                      sx={{
                        ...ProgressCardCommonStyle(
                          index,
                          themeColors.primaryType1,
                          themeColors.secondaryType2,
                          discountData.length,
                          discount,
                          isSKUOffer
                        ),
                      }}
                    />
                    {index < discountData.length - 1 && (
                      <Box className="absolute r0 zi2 b0">
                        {selectedQuntity < discount.label ? (
                          <Box
                            className="flex fdc jc-c"
                            height={!isSKUOffer ? "30px" : "20px"}
                          >
                            <Box
                              className="closed-mark"
                              height={!isSKUOffer ? "15px" : "10px"}
                            />
                          </Box>
                        ) : (
                          <Box
                            className="flex fdc jc-sb"
                            height={!isSKUOffer ? "30px" : "20px"}
                          >
                            <Box
                              className="closed-mark-top"
                              height={!isSKUOffer ? "7.5px" : "5px"}
                            />
                            <Box
                              className="close-mark-bottom"
                              height={!isSKUOffer ? "7.5px" : "5px"}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </Grid>
                );
              })}

              {onBrandSelection && (
                <Grid xs={1} item className="zi3">
                  <Box className="flex jc-c" sx={{ height: "20px" }}>
                    <Box>
                      <IconButton onClick={onBrandSelection}>
                        <Box
                          className="flex ai-c jc-c plus-icon"
                          sx={{
                            background: themeColors.primaryType1,
                          }}
                        >
                          +
                        </Box>
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid container className="absolute b0">
              {purchaseData.map((discount, index: number) => {
                return (
                  <Grid xs item key={discount.label} className="relative">
                    <LinearProgress
                      variant="determinate"
                      value={discount.fillValue ? discount.fillValue : 0}
                      sx={{
                        ...ProgressCardCommonStyle(
                          index,
                          "#3f3f3f",
                          "transparent",
                          discountData.length,
                          discount,
                          isSKUOffer
                        ),
                      }}
                    />
                  </Grid>
                );
              })}
              {onBrandSelection && <Grid xs={1} item />}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProgressCard;
