export const URLS = {
  valid_offer: (loc: string, phoneNumber: number | string) =>
    `/authenticated/pharmacy-users/offers?loc=${loc}&noOfPharmacies=${3}&phoneNumber=${encodeURIComponent(
      phoneNumber
    )}`,
  intermediate_orders: (loc: string, phoneNumber: number | string) =>
    `/authenticated/intermediate-orders?loc=${loc}&phoneNumber=${encodeURIComponent(
      phoneNumber
    )}`,
  localization: (loc: string) => `/api/localLanguages?loc=${loc}`,
  downloadOrderHistory: "/authenticated/orders/export/csv",
  getProfile: (phone: number | string, loc: string) =>
    `/authenticated/pharmacy-users/profile?mobile=${encodeURIComponent(
      phone
    )}&loc=${loc}`,
  getOffer: (phoneNo: any, noOfPharmacies: number | string, loc: string) =>
    `/authenticated/v2/pharmacy-users/offers?phoneNumber=${encodeURIComponent(
      phoneNo
    )}&loc=${loc}&noOfPharmacies=${noOfPharmacies}`,
  placeOrder: "/authenticated/orders?email=true",
  deleteOrder: (deleteOrderId: string | number) =>
    `/authenticated/orders/${deleteOrderId}`,
  getClientList: (phoneNo: number | string, loc: string) =>
    `/authenticated/client-pos-account/clientPosMappingTORepCustomer?loc=${loc}&phoneNumber=${encodeURIComponent(phoneNo)}`,
  updatedOfferApi: (userId: number, loc: string, phoneNo: any) =>
    `authenticated/v3/pharmacy-users/offers?userId=${userId}&loc=${loc}&phoneNumber=${encodeURIComponent(
      phoneNo
    )}`,
  getProfileWithPreferences: (phone: number | string, loc: string) =>
    `/authenticated/pharmacy-users/profileMaster?mobile=${encodeURIComponent(
      phone
    )}&loc=${loc}`,
  updateUserPreferences: (phone: number | string, loc: string) => `/authenticated/pharmacy-users/updateMasterProfileConsent?mobile=${encodeURIComponent(
      phone
    )}&loc=${loc}`,
  getDraftData: (user_id: number, loc: string) => `/authenticated/getDraftDetails?userIdentifier=${user_id}&loc=${loc}`,
  createDraft: '/authenticated/draftOrders',
  draftToOrder: '/authenticated/confirmDraftOrder?email=false',
  getTNCData: (loc: string) => `/api/terms-and-conditions?loc=${loc}`,
  deleteOrderLineItems: '/authenticated/orders',
  deleteDrafts: '/authenticated/draftOrders'
};