/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { sendEvent } from "../../../utils/helperFunctions/sendEvent";
import CheckoutFormTabs from "./FormTabs";
import { useEffect, useState } from "react";
import {
  BotEventData,
  Locale,
  RepData,
  setBotEventData,
  User,
} from "../../../utils/redux";
import { fetchFromStorage } from "../../../utils/storage";
import { identifiers } from "../../../utils/constants/identifiers";
import { Typography } from "@mui/material";

const CheckoutForm = ({ isOrderHistory = false }) => {
  const locale = useSelector(Locale);
  const [sendHistoryTabEvent, setSendHistoryTabEvent] = useState(true);
  const [orderHistoryFlow, setOrderHistoryFlow] = useState(isOrderHistory);
  const [isReloaded, setIsReloaded] = useState(false);
  const [editOrderData, setEditOrderData] = useState(null);
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [reload, setReload] = useState(0);
  const [labels, setLabels] = useState({});
  const dispatch = useDispatch();
  const user = useSelector(User);
  const repData = useSelector(RepData);
  const botEventData = useSelector(BotEventData);
  const [activeTab, setActiveTab] = useState(
    botEventData?.orderflow?.toLowerCase() === "edit" ? 2 : 1
  );

  useEffect(() => {
    if(locale?.general?.skuOrdering?.skuOrdering){
      setLabels(locale?.general?.skuOrdering?.skuOrdering);
    }
  }, [locale]);

  useEffect(() => {
    if (reload > 0) {
      setRefreshComponent(true);
    }
  }, [reload]);

  useEffect(() => {
    if (refreshComponent) {
      setEditOrderData(null);
      setRefreshComponent(false);
    }
  }, [refreshComponent]);

  useEffect(() => {
    if (botEventData?.orderflow === "edit") {
      setReload(reload + 1);
      setActiveTab(2);
    } else {
      setActiveTab(1);
      setReload(reload + 1);
      setIsReloaded(false);
    }
  }, [botEventData]);

  useEffect(() => {
    if (orderHistoryFlow) {
      dispatch(
        setBotEventData({
          token: fetchFromStorage(identifiers.token),
          orderflow: "edit",
          phone: user.phone,
          formtype: "type1",
          noOfPharmacies: 1,
          repPhone: repData?.phone ? repData.phone : user.phone,
        })
      );
    }
  }, []);

  useEffect(() => {
    setOrderHistoryFlow(isOrderHistory);
  }, [isOrderHistory]);
  return (
    <div className="main-container">
      {!orderHistoryFlow && (
        <div className="flex g10">
          <Typography
            className={`flex f1 ai-e order-tab ${
              activeTab === 1 ? "active-tab" : "inactive-tab"
            }`}
            onClick={() => {
              if (activeTab !== 1 && isReloaded && !editOrderData) {
                sendEvent("new-order-clicked", {});
              } else if (
                activeTab !== 1 &&
                !editOrderData &&
                botEventData?.orderflow === "edit"
              ) {
                sendEvent("new-order-clicked", {});
              } else {
                setActiveTab(1);
              }
            }}
          >
            {labels.order_details?.toUpperCase()}
          </Typography>
          <Typography
            className={`flex f1 ai-e order-tab ${
              activeTab === 2 ? "active-tab" : "inactive-tab"
            }`}
            onClick={() => {
              setActiveTab(2);
              if (activeTab !== 2 && sendHistoryTabEvent) {
                sendEvent("order-history-tab", {});
                setSendHistoryTabEvent(false);
              }
            }}
          >
            {labels.period_orders?.toUpperCase()}
          </Typography>
        </div>
      )}
      {!refreshComponent && (
        <CheckoutFormTabs
          labels={labels}
          setIsReloaded={setIsReloaded}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          editOrderData={editOrderData}
          setEditOrderData={setEditOrderData}
          reload={reload}
          setReload={setReload}
          orderHistoryFlow={orderHistoryFlow}
          setOrderHistoryFlow={setOrderHistoryFlow}
        />
      )}
    </div>
  );
};

export default CheckoutForm;
