import { FC, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./styles.css";
import { Box, IconButton, Typography } from "@mui/material";
import EditIcon from "../../assets/svg/edit-icon";
import { SxProps, Theme } from "@mui/system";

interface AccordianProps {
  children: any;
  title: any;
  background?: string;
  color?: string;
  iconColor?: string;
  showEdit?: boolean;
  shadow?: boolean;
  customHeight?: number;
  defaultOpen?: boolean;
  expandedHeight?: number;
  padding?: number | string;
  setShowAccountList?: React.Dispatch<React.SetStateAction<boolean>>;
  sx?: SxProps<Theme>;
  isOrderDraft?: boolean;
}

const CustomAccordian: FC<AccordianProps> = ({
  children,
  title,
  background = "white",
  color = "black",
  iconColor = "grey",
  showEdit = false,
  shadow = true,
  defaultOpen = false,
  customHeight = 38,
  expandedHeight = 38,
  padding,
  setShowAccountList,
  sx,
  isOrderDraft
}) => {
  const [expanded, setExpanded] = useState(defaultOpen);

  const handleEditClick = (event: any) => {
    event.stopPropagation();
    if (setShowAccountList) {
      setShowAccountList(true);
    }
  };
  const handleChange = (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      className="accordian-card"
      sx={{
        background,
        color,
        "&::before": {
          display: "none",
        },
        boxShadow: shadow ? "0px 3px 12px rgba(84, 79, 64, 0.1)" : "none",
      }}
      onChange={handleChange}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: iconColor }} />}
        sx={{
          padding,
          minHeight: customHeight,
          maxHeight: customHeight,
          "&.Mui-expanded": {
            minHeight: expandedHeight,
            maxHeight: expandedHeight,
          },
          "&.MuiAccordionSummary-root.Mui-focusVisible": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Box className={`flex ${isOrderDraft ? "ai-fs" : "ai-c"} fullWidth`}>
          {showEdit && (
            <IconButton
              onClick={handleEditClick}
              onFocus={(event) => event.stopPropagation()}
            >
              <EditIcon sx={{ fontSize: 16 }} />
            </IconButton>
          )}
          {typeof title === "string" ? (
            <Typography fontSize={14} className="fullWidth">
              {title}
            </Typography>
          ) : (
            title
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={sx}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordian;
